//start for genaric style
.form_holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    input,
    .ant-select-selector {
        height: 44px;
        background: #F3F3F7;
        border-radius: 3px;
        border: none;
    }

    textarea {
        background: #F3F3F7;
        border-radius: 3px;
        border: none;
        resize: none;
    }

    // for full width
    .input_holder_full-width {
        width: 100%;
    }
}

// because its take on ant right by defult
.ant-form-item-label {
    text-align: unset;
}

// input style
// if you need to be two inputs on same line
.input_holder {
    width: 49%;

    //background-color: red;
    @include mq("tablet") {
        width: 100%;
    }

    // body.rtl & {
    //     background-color: green;
    // }
}

body.rtl {
    [nz-form] {
        direction: rtl;
    }
}

//select style
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding-top: 6px;
    height: 44px;
    background: #F3F3F7;
    border-radius: 3px;
    border: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 44px !important;
}