//primary color 
$primary_color:#3ABDAB; //done
@import '../../assets/css/mixins.scss';

// other colors
$black : #000; //done
$ligtBlack:#0F0F0F; //done
$white : #FFF; //done


//************************** FONTS **************************//

// @font-face {
//     src: local('Cairo Regular'), local('Cairo-Regular'), url(../sass/fonts/Cairo-Regular.ttf);
//     font-family: Cairo;
// }

// @font-face {
//     src: local('Cairo Bold'), local('Cairo-Bold'), url(../sass/fonts/Cairo-Bold.ttf);
//     font-family: Cairo;
//     font-weight: bold;
// }

// @font-face {
//     font-family: lato;
//     src: url(../sass/fonts/Nunito-Bold.ttf); // font-weight : 400
//     font-weight: bolder;
//     font-display: swap;
// }

// @font-face {
//     font-family: lato;
//     src: url(../sass/fonts/Nunito-ExtraBold.ttf); // font-weight : 400
//     font-weight: bold;
//     font-display: swap;
// }

::-webkit-scrollbar {
  width: 7px;
  cursor: grab;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  border-radius: 10px;
  cursor: grab;
  // background: #1A283A;
}

::-webkit-scrollbar-thumb {
  // background: #6d7c9033;
  background: #3ABDAB;
  border-radius: 10px;
  cursor: grab;
}
.ant-modal-footer{
  border: none !important;
  padding: 0 !important;
}
.ant-modal-content{
  border-radius: 10px !important;
}
