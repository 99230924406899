@import "~ng-zorro-antd/ng-zorro-antd.min.css";

@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

/* You can add global styles to this file, and also import other style files */
body.rtl {
  [nz-form] {
    direction: rtl;
  }
}

//import reset files
@import "../src/assets/css/variables.scss";
@import "../src/assets/css/mixins.scss";
@import "../src/assets/css/reset.scss";
@import "../src/assets/css/formComponent.scss";
@import "../src/assets/css/buttons.scss";
@import "../src/assets/css/typography.scss";
@import "../src/assets/css/tables.scss";
@import "../src/assets/css/rtl.scss";
@import "../src/assets/css/responsive.scss";

// fonts
@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("opentype");
  font-display: swap;
}



//body
html {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #fff !important;
  color: #1c1e25 !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "Poppins-Regular" !important;
  font-size: 15px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.page-container-padding {
  padding: 0 85px;

  @include mq("ipad") {
    padding: 0 15px;
  }
}

.background_text {
  color: rgba(57, 181, 74, 0.1);
  font-size: 120px;
  font-family: "Herbarium";

  body.rtl & {
    margin-left: 0px;
    margin-right: -30px;
    font-family: "FFYaseer-Regular";
  }

  @include mq("ipad") {
    font-size: 55px !important;
  }
}

.header_text_holder {
  position: absolute;
  left: 68px;
  right: 0;
  top: 35px;
  bottom: 0;

  @include mq("ipad") {
    position: relative !important;
    margin-top: -50px !important;
    top: 0 !important;
    left: 0 !important;
  }

  .header_text {
    font-size: 25px;
    color: rgba(57, 181, 74, 1);
    font-family: "Herbarium";

    body.rtl & {
      font-family: "FFYaseer-Regular";
    }

    @include mq("ipad") {
      font-size: 16px !important;
    }
  }

  .sub_header_text {
    color: #1c1e25;
    font-size: 40px;
    font-family: "Effra_Trial_bold";
    margin-bottom: 38px;


    @include mq("ipad") {
      font-size: 26px !important;
    }
  }
}

a:hover {
  color: inherit;
}

button {
  cursor: pointer;
}

select:focus {
  outline: none !important;
}

.page_title {
  @include mq("ipad") {
    font-size: 25px !important;
    max-width: 80%;
  }
}

.englsihDirection {
  p {
    direction: ltr !important;
  }
}

.opacityHeader {
  background-color: rgb(255 255 255 / 100%) !important;
}
.mobView{
  display: none !important;
  @include mq("ipad") {
    display: flex !important;

  }
}
.webView{
  display: flex !important;
  @include mq("ipad") {
    display: none !important;

  }
}
// body {
//   margin-top: 75px;
// }
.popUpText {
  .header_text_holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    bottom: auto;
    text-align: center;
  }

  .background_text {
    text-align: center;
  }

  .ant-modal-footer {
    border: none !important;
  }

  .popUpMainText {
    text-align: center;
    color: #353535;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .textMaxHeight {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 80px;

    @include mq("ipad") {
      padding: 0 15px;

    }
  }

}

.newsHolder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 85px;
  margin-bottom: 40px;

  @include mq("ipad") {
    padding: 0 15px;
    flex-direction: column;
    margin-bottom: 0px;

  }

  .newBoxHolder {
    display: flex;
    width: calc(33% - 40px);
    border-bottom: 5px solid #3BBFAD;
    flex-direction: column;
    background-color: #F3F6F9;
    cursor: pointer;

    @include mq("ipad") {
      width: 100%;
      margin-bottom: 15px;
    }

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .newHeader {
      height: 65px;
      color: #0A2240;
      font-size: 18px;
      font-family: "Effra_Trial_SB";
      margin-bottom: 10px;
      line-height: 30px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }

    .textSection {
      padding: 15px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .readText {
      color: #00758D;
      font-size: 12px;
      font-family: "Effra_Trial_Md";
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 15px;
        height: 15px;
        margin: 0 5px;

        body.rtl & {
          transform: rotate(180deg);

        }
      }
    }
  }
}

.mystyle {
  overflow-y: hidden !important;
}

.mystylehtml {
  top: 0 !important;
}