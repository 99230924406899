/*Primary (Fill)*/

.Primary_fill {
    @include btn($primary_color, $white, $primary_color);
}

.Primary_fill:hover {
    @include btn($primary_color, $white, $primary_color);
}

.Primary_fill:active {
    @include btn($primary_color, $white, $primary_color);
}
.Primary_fill:focus {
    @include btn($primary_color, $white, $primary_color);
}

.Primary_fill:disabled {
    @include btn($primary_color, $white, $primary_color);
    cursor: not-allowed;
}

button:focus {
    outline: none;
}
